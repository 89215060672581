import {config} from "../../../util/configs"
import axios from "axios";
import {Auth, API} from "aws-amplify"

export async function getUser(username){
    if(!username){
        return null;
    }

    let apiName = config.apiName;
    let path = config.getUserPath;

    let init = {
        body: {
            username
        },
        headers: {
            "Content-Type": "application/json"
        }
    }

    try {
        let result = await API.post(apiName, path, init);
        return result;
    }catch(e){
        return null;
    }
}

export async function isUserApproved(username){
    if(!username){
        return false;
    }

    let apiName = config.apiName;
    let path = config.isUserApprovedPath;

    let init = {
        body: {
            username
        },
        headers: {
            "Content-Type": "application/json"
        }
    }

    try {
        let result = await API.post(apiName, path, init);
        return result;
    }catch(e){
        return false;
    }
}

export async function approveUser(username, customCredentials){
    if(!username){
        return false;
    }

    let apiName = config.apiName;
    let path = config.approveUserPath;

    let init = {
        body: {
            username,
            customCredentials: customCredentials
        },
        headers: {
            "Content-Type": "application/json"
        }
    }

    try {
        let result = await API.post(apiName, path, init);
        return result;
    }catch(e){
        return false;
    }
}